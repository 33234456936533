import type { PageContentSidebarItemAction, PageContentSidebarItem } from '@/definitions/_layout/pageContent/sidebar/types'

const actions: PageContentSidebarItemAction[] = [
  {
    text: 'Edit pipeline',
    icon: 'edit',
  },
  {
    text: 'View details',
    icon: 'visibility',
  },
  {
    text: 'Archive',
    icon: 'tmi-inbox-open',
  },
  {
    text: 'Delete',
    icon: 'delete',
  },
]

export const dealsSidebarRoutes: PageContentSidebarItem[] = [
  {
    itemType: 'title',
    title: 'Pipelines',
  },
  {
    itemType: 'link',
    title: 'Sales US',
    number: '12',
    flag: {
      id: 'us',
      name: 'USA',
    },
    route: { name: 'base.deals.index' },
    actions: actions,
  },
  {
    itemType: 'link',
    title: 'Sales UK',
    number: '31',
    flag: {
      id: 'uk',
      name: 'United Kingdom',
    },
    route: { name: 'base.deals.emptyRoute' },
    actions: actions,
  },
]

export const dealsSettingsSidebarRoutes: PageContentSidebarItem[] = [
  {
    itemType: 'title',
    title: 'Active pipelines',
  },
  {
    itemType: 'link',
    title: 'Sales US',
    route: { name: 'base.deals.settings.detailed' },
  },
  {
    itemType: 'link',
    title: 'Sales UK',
    route: { name: 'base.deals.settings.detailedEmpty' },
  },
  {
    itemType: 'link',
    title: 'Local clients',
    route: { name: 'base.deals.settings.detailedEmpty' },
  },
]

export const dealsSettingsArchivedSidebarRoutes: PageContentSidebarItem[] = [
  {
    itemType: 'title',
    title: 'Archive',
  },
  {
    itemType: 'link',
    title: 'Summer campaign',
    route: { name: 'base.deals.settings.detailedEmpty' },
  },
]

export const dealsSettingsIndexRoute: PageContentSidebarItem[] = [
  {
    itemType: 'link',
    title: 'All pipelines',
    route: { name: 'base.deals.settings.index' },
  },
]
