
import { defineComponent } from 'vue'
import PageContentSidebarList from '@/components/layout/pageContent/sidebar/PageContentSidebarList.vue'
import { dealsSettingsSidebarRoutes, dealsSettingsArchivedSidebarRoutes, dealsSettingsIndexRoute } from '@/router/sidebarRoutes/dealsRoutes'

export default defineComponent({
  components: {
    PageContentSidebarList,
  },
  setup() {
    return {
      dealsSettingsSidebarRoutes,
      dealsSettingsArchivedSidebarRoutes,
      dealsSettingsIndexRoute,
    }
  },
})
